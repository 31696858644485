<template>
  <div class="bg">
    <div class="video-background">
      <div class="overlay"></div>
    </div>
    <v-form v-model="valid" ref="form" class="pt-16">
      <v-card elevation="0" class="mx-auto pe-5 mt-16" tile width="900">
        <v-row class="">
          <v-col cols="12" md="4" sm="6" class="py-0 pe-0 me-0" align="center">
            <v-img
              src="../assets/images/diklat-potrait.jpg"
              height="100%"
              width="100%"
            ></v-img>
          </v-col>
          <v-col cols="12" md="8" sm="6" class="pb-16 px-8" align="center">
            <div class="d-flex justify-start mt-3">
              <v-btn icon route to="/login">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-column mt-4">
              <span class="text-center text-h6 font-weight-black"
                >REGISTRASI AKUN BARU</span
              >
            </div>
            <v-row class="mt-6 mb-0 py-0">
              <v-col cols="12" md="7" sm="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  v-model="user.username"
                  label="NIP (username)"
                  :rules="rulesNotEmtpy"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  dense
                  type="password"
                  label="Password"
                  :rules="rulesNotEmtpy"
                  outlined
                  v-model="user.password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  dense
                  outlined
                  v-model="user.email"
                  label="Email"
                  :rules="[validateNotEmpty, validateEmail]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  dense
                  outlined
                  v-model="user.fullName"
                  label="Nama Lengkap"
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" md="6" sm="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  prepend-inner-icon="mdi-whatsapp"
                  v-model="user.phone"
                  :rules="[validateNotEmpty, validatePhoneNumber]"
                  dense
                  required
                  placeholder="08.."
                  outlined
                  label="Whatsapp"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" class="my-0 py-0">
                <v-menu
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="my-0 py-0"
                      :value="computedDateFormattedDatefns(user.birthDate)"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      clearable
                      label="Tanggal Lahir"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="user.birthDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="user.birthDate"
                    @change="menuDate1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="my-0 pt-0">
              <v-col cols="12" sm="6" md="12" class="my-0 py-0">
                <v-autocomplete
                  class="my-0 py-0"
                  v-model="user.fdivisionBean"
                  :items="itemsFDivision"
                  :rules="rulesNotEmtpy"
                  item-value="id"
                  item-text="description"
                  auto-select-first
                  dense
                  chips
                  outlined
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Dinas/OPD"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="font-italic red--text">
                  Pastikan data yang dimasukkan sudah benar
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="mx-4 mt-4">
              <v-spacer />
              <div>
                <v-btn color="primary" @click="handleSignUp" width="200px"
                  >Sign up</v-btn
                >
              </div>
              <v-spacer />
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-spacer></v-spacer>
  </div>
</template>
  
  <script>
import { format, parseISO } from "date-fns";
import AuthService from "@/services/auth-service";
import User from "@/models/user";
import ERole from "@/models/e-role";
import FDivisionService from "@/services/apiservices/f-division-service";
import EOrganizationLevel from "@/models/e-organization-level";

export default {
  name: "RegisterView",
  components: {},
  data() {
    return {
      valid: false,
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
      validateNotEmpty: (value) => !!value || "Tidak Boleh Kosong",
      validateEmail: (value) => {
        const emailRegex = /^[a-zA-Z0-9]{2,}@gmail\.com$/;
        return emailRegex.test(value) || "Format Email Salah";
      },
      validatePhoneNumber: (value) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(value) || "Hanya Boleh Input Angka";
      },

      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDate1: false,

      itemsFDivision: [],
      user: new User("", ""),
      loading: false,
      snackbar: false,
      message: "",
      success: false,

      itemsRoles: [ERole.ROLE_ADMIN, ERole.ROLE_USER],
    };
  },
  computed: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    // loggedIn() {
    //   return this.$store.state.auth.status.loggedIn;
    // }
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/profile');
    // }
  },
  methods: {
    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMMM yyyy") : "";
    },
    fetchParent() {
      FDivisionService.getAllFDivisionPublic().then((response) => {
        this.itemsFDivision = response.data.filter(
          (item) => item.statusActive === true
        );
        // console.log(JSON.stringify(response.data))
      });
    },

    handleSignUp() {
      if (this.$refs.form.validate()) {
        this.success = false; //diReset dulu
        let requestUser = this.user;
        requestUser.roles = [ERole.ROLE_USER];
        requestUser.isAccountNonLocked = true;
        requestUser.organizationLevel = EOrganizationLevel.DIV;
        requestUser.isAccountNonLocked = false;

        AuthService.registerPublic(requestUser).then(
          () => {
            this.success = true;
            this.message = `Create User ${this.user.username} sukses `;
            this.snackbar = true;
            this.$router.push("/login");
          },
          (error) => {
            console.log(error);
            this.message = `Fail create ${this.user.username} sukses `;
            this.message = `Fail create user `;
            this.snackbar = true;
          }
        );
      }
    },
  },
  mounted() {
    this.fetchParent();
  },
};
</script>
  <style scoped>
.overlay {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100vh;
  z-index: 1;
  opacity: 0.9;
}
.video-background {
  background-image: url("../../public/assets/carousel/carousel-1.jpg");
  background-repeat: no-repeat; /* Optional: Do not repeat the image */
  background-size: cover; /* Optional: Cover the entire container */
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
}
.bg {
  background: black;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}
/*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/
/*background-color: lightyellow;*/
/*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/
.glass-bg {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.floating-button {
  z-index: 99;
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
}
.footer_image {
  background-image: url("https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"),
    linear-gradient(#eb01a5, #d13531);
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
  
  
  